import { PMBSchemas } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const monthEnum = new EnumFormatter<PMBSchemas["Month"]>({
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
});
