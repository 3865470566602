import {
  CheckCircleOutlined,
  DoNotDisturb,
  ErrorOutline,
  RemoveCircleOutline,
  Schedule,
} from "@mui/icons-material";
import { Card, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactElement, ReactNode } from "react";
import {
  AlertCircle,
  Cpu,
  CreditCard,
  Edit3,
  Monitor,
  Box as BoxIcon,
} from "react-feather";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { PMBSchemas } from "../../client/types";
import { locationStatus } from "../../constants/locationStatus";
import { Address } from "../../ui/address";
import { LocationBillingToast } from "../../ui/billing-toast";
import { DialogIconButton } from "../../ui/dialog-button";
import { EditLocationAddressDialog } from "../../ui/edit-location-address-dialog";
import { EditLocationNameDialog } from "../../ui/edit-location-dialog";
import { EditLocationShippingAddressDialog } from "../../ui/edit-location-shipping-address-dialog";
import { LocationFetchErrorCard } from "../../ui/info-card";
import LoadingSpinner from "../../ui/loading-spinner";
import { LocationContactListCard } from "../../ui/location-contact-list-card";
import { LocationSubscriptionDetailsCardFetch } from "../../ui/location-subscription-details-card";
import { NameCard } from "../../ui/name-card";
import { RoleGuard, useUserHasRole } from "../../ui/role-guard";
import { ViewCard } from "../../ui/view-card";
import { ViewHeader } from "../../ui/view-header";
import { EnumFormatter, EnumMapper } from "../../utils/enum-formatter";
import { formatDate } from "../../utils/formatters";
import { LocationDevicesCard } from "../../ui/location-devices-card";
import { LocationInternalInfoCard } from "../../ui/location-internal-info-card";
import { deviceStatusV2 } from "../../constants/locationConfiguration/deviceStatus";
import { countBy, groupByToEntries } from "../../utils/common";
import { getCommonGroupStatus } from "../Device/device-cards";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const PmbTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

export function LocationProfileFetch() {
  const { locationId } = useParams<"locationId">();
  const { data, error, mutate } = useClientSWR("/locations/{locationId}", {
    params: { path: { locationId: Number(locationId) } },
  });

  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  if (data) {
    return <LocationProfileView {...data} revalidateView={() => mutate()} />;
  }

  return <LoadingSpinner />;
}

const deviceCategory = new EnumFormatter<
  PMBSchemas["DeviceUpdate"]["deviceCategory"]
>({
  CHARGER: "Charger",
  GATEWAY: "Gateway",
  SCREEN: "Screen",
});

const deviceCategoryIcon = new EnumMapper<
  PMBSchemas["DeviceUpdate"]["deviceCategory"],
  ReactNode
>(
  {
    CHARGER: <CreditCard />,
    GATEWAY: <Cpu />,
    SCREEN: <Monitor />,
  },
  <AlertCircle />,
);

const deviceCategoryOrder = new EnumMapper<
  PMBSchemas["DeviceUpdate"]["deviceCategory"],
  number
>(
  {
    GATEWAY: 4,
    CHARGER: 3,

    // Device group is order 2
    SCREEN: 1,
  },
  -1,
);

function summariesStatuses(statuses: [string, number][]) {
  if (statuses.length === 1) {
    return statuses[0][0];
  }

  return statuses.map(([status, count]) => `${count}x ${status}`).join(", ");
}

function summariseLabel(count: number, label: string) {
  return `${count}x ${label}`;
}

function LocationDevicesCardFetch(props: { locationId: number }) {
  const { locationId } = props;
  const { data: devices, error } = useClientSWR(
    "/locations/{locationId}/devices",
    {
      params: { path: { locationId: locationId } },
    },
  );
  const { data: deviceGroups, error: deviceGroupsError } = useClientSWR(
    "/locations/{locationId}/device-groups",
    {
      params: { path: { locationId: locationId } },
    },
  );

  if (error || deviceGroupsError) {
    return (
      <Card>
        <Box display="flex" alignItems="center" justifyContent="center">
          Error Fetching Devices
        </Box>
      </Card>
    );
  }
  if (devices && deviceGroups) {
    const groups = deviceGroups.content;
    const deviceGroupsSummary =
      groups.length === 0
        ? null
        : {
            label: summariseLabel(groups.length, "Device group"),
            icon: <BoxIcon />,
            order: 2,
            status: summariesStatuses(
              Object.entries(
                countBy(
                  groups.map((g) => getCommonGroupStatus(g.devices ?? [])),
                  (g) => g,
                ),
              ),
            ),
          };

    const devicesSummaries = groupByToEntries(
      devices,
      (d) => d.deviceCategory,
    ).map(([category, devices]) => {
      if (devices.length === 0) {
        return null;
      }

      return {
        label: summariseLabel(devices.length, deviceCategory.get(category)),
        icon: deviceCategoryIcon.get(category),
        status: summariesStatuses(
          Object.entries(
            countBy(devices, (d) => deviceStatusV2.format(d.status)),
          ),
        ),
        order: deviceCategoryOrder.get(category),
      };
    });

    return (
      <LocationDevicesCard
        locationId={locationId}
        summaries={[...devicesSummaries, deviceGroupsSummary]
          .filter((item): item is NonNullable<typeof item> => !!item)
          .sort((a, b) => b.order - a.order)}
      />
    );
  }

  return <LocationDevicesCard locationId={locationId} />;
}

const statusIconSrc = new EnumMapper<
  PMBSchemas["LocationStatus"],
  ReactElement
>(
  {
    ACTIVE: <CheckCircleOutlined color="success" />,
    PENDING_INSTALLATION: <Schedule color="warning" />,
    PENDING_OPENING: <Schedule color="warning" />,
    CLOSED: <RemoveCircleOutline color="disabled" />,
    LEGACY: <RemoveCircleOutline color="disabled" />,
    NOT_SUPPORTED: <DoNotDisturb color="info" />,
  },
  <ErrorOutline color="error" />,
);

function getStatusInfo(location: PMBSchemas["LocationBase"]) {
  switch (location.locationStatus) {
    case "PENDING_INSTALLATION":
      return location.installDate
        ? `Exp. ${formatDate(location.installDate, "medium")}`
        : null;

    case "PENDING_OPENING":
      return location.grandOpeningDate
        ? `Exp. ${formatDate(location.grandOpeningDate, "medium")}`
        : null;

    case "LEGACY":
      return "Location devices are not connected.";

    default:
      return null;
  }
}

function StatusCard(props: { location: PMBSchemas["LocationBase"] }) {
  const { location } = props;
  const icon = statusIconSrc.get(location.locationStatus);
  const label = locationStatus.get(location.locationStatus);
  const info = getStatusInfo(location);

  return (
    <ViewCard
      title="Status"
      content={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            color: "warning",
          }}
        >
          {icon}

          <div>
            <Typography variant="body1">{label}</Typography>
            {info && <Typography variant="body2">{info}</Typography>}
          </div>
        </Box>
      }
    />
  );
}

function LocationNameCard(
  props: PMBSchemas["LocationBase"] & {
    revalidateView: () => void;
  },
) {
  const userCanNavigateToCompanyDetail = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "PMB_VIEWER",
    "COMPANY_ADMIN",
  ]);
  const userCanUpdateLocation = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "LOCATION_ADMIN",
    "COMPANY_ADMIN",
  ]);
  const userCanUpdateCustomerNumber = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
  ]);

  return (
    <NameCard
      name={props.name}
      subheading={`Customer ID: ${props.customerNumber}`}
      action={
        userCanUpdateLocation && (
          <DialogIconButton
            label="Edit location"
            renderDialog={(dialogProps) => (
              <EditLocationNameDialog
                {...dialogProps}
                location={props}
                onSuccess={props.revalidateView}
                userCanUpdateCustomerNumber={userCanUpdateCustomerNumber}
              />
            )}
          >
            <Edit3 />
          </DialogIconButton>
        )
      }
      footer={
        <Box sx={{ textAlign: "center", padding: 4 }}>
          {"Billed to "}

          {userCanNavigateToCompanyDetail ? (
            <Link
              component={ReactRouterLink}
              to={`/company/${props.company?.id}/profile`}
            >
              {props.company?.name}
            </Link>
          ) : (
            props.company?.name
          )}
        </Box>
      }
    />
  );
}

function LocationProfileView(
  props: PMBSchemas["LocationBase"] & { revalidateView: () => void },
) {
  return (
    <>
      <ViewHeader
        title={props.name}
        breadcrumb={[
          {
            label: "Locations",
            url: `/location/list`,
          },
          {
            label: props.name,
          },
        ]}
      />

      <LocationBillingToast locationId={props.id} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={3}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <LocationNameCard {...props} />

            <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
              <StatusCard location={props} />
            </RoleGuard>

            <RoleGuard
              allowedRoles={[
                "PMB_ADMIN",
                "PMB_VIEWER",
                "COMPANY_ADMIN",
                "LOCATION_ADMIN",
              ]}
            >
              <LocationSubscriptionDetailsCardFetch locationId={props.id} />
            </RoleGuard>

            <ViewCard
              title="Location Address"
              content={<Address {...props.address} />}
              headerAction={
                <RoleGuard
                  allowedRoles={[
                    "PMB_ADMIN",
                    "COMPANY_ADMIN",
                    "LOCATION_ADMIN",
                  ]}
                >
                  <DialogIconButton
                    label="Edit location address"
                    renderDialog={(dialogProps) => (
                      <EditLocationAddressDialog
                        {...dialogProps}
                        location={props}
                        onSuccess={props.revalidateView}
                      />
                    )}
                  >
                    <Edit3 />
                  </DialogIconButton>
                </RoleGuard>
              }
            />

            <ViewCard
              title="Shipping Address"
              content={
                props.shippingAddress ? (
                  <Address {...props.shippingAddress} />
                ) : (
                  <Typography variant="body2">Not specified.</Typography>
                )
              }
              headerAction={
                <RoleGuard
                  allowedRoles={[
                    "PMB_ADMIN",
                    "COMPANY_ADMIN",
                    "LOCATION_ADMIN",
                  ]}
                >
                  <DialogIconButton
                    label="Edit shipping address"
                    renderDialog={(dialogProps) => (
                      <EditLocationShippingAddressDialog
                        {...dialogProps}
                        location={props}
                        onSuccess={props.revalidateView}
                      />
                    )}
                  >
                    <Edit3 />
                  </DialogIconButton>
                </RoleGuard>
              }
            />

            <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
              <LocationInternalInfoCard {...props} />
            </RoleGuard>
          </Box>
        </Grid>

        <Grid item xs={12} lg={9}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12}>
              <LocationDevicesCardFetch locationId={props.id} />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <LocationContactListCard {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
