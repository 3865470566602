import { PMBSchemas } from "../client/types";
import { useUserInfo } from "../ui/user-info-context";

type Permission =
  | {
      type: "CHANGE_SUBSCRIPTION_PAYMENT_FREQUENCY";
      subscription: PMBSchemas["Subscription"];
    }
  | {
      type: "VIEW_ANNUAL_PROMO";
      subscription: PMBSchemas["Subscription"];
    }
  | {
      type: "MANAGE_COMPANY_PAYMENT_METHODS";
    };

export function useQueryPermissions<T extends Permission>(queries: T[]) {
  const { roles } = useUserInfo();
  const permissions = new Map<T["type"], boolean>();

  for (const query of queries) {
    switch (query.type) {
      case "CHANGE_SUBSCRIPTION_PAYMENT_FREQUENCY": {
        permissions.set(
          query.type,
          roles.includes("COMPANY_ADMIN") && !query.subscription.isSeasonal,
        );

        break;
      }

      case "VIEW_ANNUAL_PROMO": {
        permissions.set(
          query.type,
          roles.includes("COMPANY_ADMIN") &&
            query.subscription.paymentFrequency === "MONTHLY" &&
            !query.subscription.isSeasonal,
        );

        break;
      }

      case "MANAGE_COMPANY_PAYMENT_METHODS": {
        permissions.set(query.type, roles.includes("COMPANY_ADMIN"));
        break;
      }
    }
  }

  return {
    isAllowed(query: T["type"]) {
      return permissions.get(query) ?? false;
    },
  };
}
